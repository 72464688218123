import { createSlice } from '@reduxjs/toolkit'
import name from './name'

const initialState = {
  loadingAssessment: true,
  metaSkills: new Array(4).fill({}),
  benchmarks: [],
  hasError: false
}

export const assessments = createSlice({
  name,
  initialState,
  reducers: {
    setAssessmentDetails: (state, { payload }) => {
      state.metaSkills = payload.metaSkills
      state.benchmarks = payload.benchmarkScores
      state.loadingAssessment = false
    },
    setErrorState: state => {
      state.hasError = true
    }
  }
})

export const {
  setAssessmentDetails,
  setErrorState
} = assessments.actions

export default assessments.reducer
