import * as actions from './slice'

export const launchAssessment = ({ onSuccess }) => {
  return {
    eventName: 'msaLaunchAssessment',
    onSuccess: [
      onSuccess
    ]
  }
}

export const getAssessmentDetails = ({ onError }) => {
  return {
    eventName: 'msaGetAssessmentDetails',
    onSuccess: {
      action: actions.setAssessmentDetails, redux: true
    },
    onError: [
      onError
    ]
  }
}

export const downloadReport = ({ onSuccess }) => {
  return {
    eventName: 'msaDownloadReport',
    onSuccess: [
      onSuccess
    ]
  }
}
