/* eslint-disable indent */
import React from 'react'
import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useFormFactor } from '../../hooks'

const useStyles = makeStyles(({ palette }) => ({
  legal: {
    color: palette.grey.shade[60],
    fontSize: '0.875rem'
  }
}))

const mobileFormat = [
  `\u00a9 Mind Tools Ltd ${new Date().getFullYear()}.`,
  'All rights reserved.',
  '\u201cMind Tools\u201d is a registered trademark',
  'of Mind Tools Ltd.'
]

const tabletFormat = [
  mobileFormat.slice(0, 2).join(' '),
  mobileFormat.slice(2, 4).join(' ')
]

const largeFormat = [mobileFormat.join(' ')]

const Legal = () => {
  const classes = useStyles()
  const { isMobile, isSmallTablet } = useFormFactor()

  const text = isMobile
    ? mobileFormat
    : isSmallTablet
    ? tabletFormat
    : largeFormat

  return text.map((line, index) => (
    <Typography
      align='center'
      key={index}
      className={classes.legal}
      data-test='footer-legal'
    >
      {line}
    </Typography>
  ))
}

export default Legal
