import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(({ spacing, typography, breakpoints, palette }) => ({
  root: {
    background: '#F8F9FD',
    padding: `${spacing(3)} ${spacing(5)}`,
    marginTop: spacing(1)
  },
  title: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '24px',
    marginTop: spacing(4),
    marginBottom: spacing(4)
  },
  message: {
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 400
  }
}))
