import name from './name'
import { createSlice } from '@reduxjs/toolkit'
import { EncryptedCookies } from '@emerald-works-nova/components'
import Cookies from 'universal-cookie'
import jwt from 'jsonwebtoken'

const cookies = new Cookies()

const CLOUD_FRONT_COOKIES = ['CloudFront-Key-Pair-Id', 'CloudFront-Policy', 'CloudFront-Signature']

const initialState = {
  profile: null,
  isProfileLoaded: false,
  hasProfileLoadError: false,
  authEvents: []
}

const user = createSlice({
  name,
  initialState,
  reducers: {
    setProfile: (state, { payload }) => {
      state.profile = { ...state.profile, ...payload }
      state.isProfileLoaded = true
      if (payload.defaultLanguage) {
        localStorage.setItem('defaultLanguage', payload.defaultLanguage)
      }
      EncryptedCookies.setSession(JSON.stringify({ features: state?.profile?.features }))
    },
    setProfileLoadError: (state, { payload }) => {
      state.hasProfileLoadError = payload
    },
    createCookie: (state, { payload }) => {
      const domainParts = window.location.hostname.split('.')
      const tenantKey = domainParts.shift()
      const domain = domainParts.join('.')
      const expires = new Date(new Date().getTime() + 1 * 60 * 60 * 1000)
      const allCookies = cookies.getAll()
      const cognitoCookieName = Object.keys(cookies.getAll()).find(
        cookie =>
          cookie.startsWith('CognitoIdentityServiceProvider') &&
          cookie.endsWith('idToken')
      )
      const cognitoCookie = allCookies[cognitoCookieName]
      const cookieData = payload
      const cloudfrontCookies = payload.cloudfrontCookies
      delete cookieData.cloudfrontCookies
      if (cognitoCookie) {
        const decoded = jwt.decode(cognitoCookie)
        cookieData.exp = decoded.exp
        cookieData.auth_time = decoded.auth_time
        cookieData.iat = decoded.iat
      }
      cookieData.tenant_key = tenantKey
      cookieData.website_url = window.location.origin
      const jwtPayload = jwt.sign(
        JSON.stringify(cookieData),
        process.env.REACT_APP_JWT_SECRET || 'secret'
      )
      cookies.set('Nova.CurrentSession', jwtPayload, {
        path: '/',
        domain,
        expires
      })
      if (cloudfrontCookies) {
        Object.entries(cloudfrontCookies).forEach(([name, value]) => {
          cookies.set(name, value, {
            path: '/',
            domain: window.location.hostname,
            expires,
            secure: true
          })
        })
      } else {
        CLOUD_FRONT_COOKIES.forEach(cookie => {
          cookies.remove(cookie)
        })
      }
    },
    addAuthEvent: (state, { payload }) => {
      state.authEvents.push(payload)
    },
    setAuthEvents: (state, { payload }) => {
      state.authEvents = payload
    }
  }
})

export const { setProfile, setProfileLoadError, createCookie, addAuthEvent, setAuthEvents } = user.actions

export default user.reducer
