import name from './name'

const selectSliceData = state => state[name]

const selectAuthors = state => selectSliceData(state).authors

const selectContent = state => selectSliceData(state).content

const selectContentTranslation = state => selectSliceData(state).translation

const selectShowModal = state => selectSliceData(state).modal

const selectAllLanguages = state => selectSliceData(state).allLanguages

const selectIsTranslating = state => selectSliceData(state).isTranslating

const selectIsUrlAdjusted = state => selectSliceData(state).isUrlAdjusted
const selectIsPreview = state => selectSliceData(state).isPreview
const selectIsExclusive = state => selectSliceData(state).isExclusive
const selectIsMeteringEnabled = state => selectSliceData(state).isMeteringEnabled
const selectMeteringLimit = state => selectSliceData(state).meteringLimit
const selectMeteringUsed = state => selectSliceData(state).meteringUsed
const selectIsLoadingExtraInfo = state => selectSliceData(state).isLoadingExtraInfo

const selectShowMeternigCallToAction = state => {
  const { meteringLimit, meteringUsed, isPreview } = selectSliceData(state)
  return !isPreview && meteringLimit && meteringUsed
}

const selectLanguage = state => {
  const code = selectSliceData(state).language
  return selectAllLanguages(state).find(({ value }) => value === code)
}

export {
  selectContent,
  selectContentTranslation,
  selectAuthors,
  selectShowModal,
  selectAllLanguages,
  selectLanguage,
  selectIsTranslating,
  selectIsUrlAdjusted,
  selectIsLoadingExtraInfo,
  selectIsPreview,
  selectIsExclusive,
  selectIsMeteringEnabled,
  selectMeteringLimit,
  selectMeteringUsed,
  selectShowMeternigCallToAction
}
