import React from 'react'
import { Grid } from '@mui/material'
import {
  PrivacyPolicyLink,
  CookiesPolicyLink,
  TandCLink,
  ContactLink,
  A11yStatementLink,
  B2BPrivacyPolicyLink,
  B2BCookiesPolicyLink,
  B2BTandCLink,
  B2BContactLink
} from './links'
import { useFormFactor } from '../../hooks'
import makeStyles from '@mui/styles/makeStyles'
import { useTenant } from '@emerald-works-nova/auth'
import consts from '../../consts'

const useStyles = makeStyles(({ palette, spacing, typography }) => ({
  links: {
    paddingLeft: spacing(3),
    paddingRight: spacing(3),
    '& *': {
      color: palette.grey.shade[80],
      fontWeight: typography.fontWeightMedium,
      fontSize: '1rem'
    }
  }
}))

const LinkPanel = () => {
  const classes = useStyles()
  const tenant = useTenant()
  const isConsumerTenant = tenant?.tenantKey === consts.DEFAULT_CLUB_PK
  const { isMobile, isSmallTablet } = useFormFactor()
  const isSmall = isMobile || isSmallTablet
  return (
    <nav>
      <Grid
        container
        direction={isSmall ? 'column' : 'row'}
        alignItems='center'
        justifyContent={isSmall ? 'center' : 'space-around'}
        spacing={isSmall ? 3 : 0}
        className={classes.links}
      >
        <Grid item xs={12} sm='auto'>
          {isConsumerTenant ? <PrivacyPolicyLink /> : <B2BPrivacyPolicyLink />}
        </Grid>
        <Grid item xs={12} sm='auto'>
          {isConsumerTenant ? <CookiesPolicyLink /> : <B2BCookiesPolicyLink />}
        </Grid>
        <Grid item xs={12} sm='auto'>
          <A11yStatementLink />
        </Grid>
        <Grid item xs={12} sm='auto'>
          {isConsumerTenant ? <TandCLink /> : <B2BTandCLink />}
        </Grid>
        <Grid item xs={12} sm='auto'>
          {isConsumerTenant ? <ContactLink /> : <B2BContactLink />}
        </Grid>
      </Grid>
    </nav>
  )
}

export default LinkPanel
