import queryString from 'query-string'
import { partialRight } from 'lodash'
import { encodeQueryData } from './encode-query-params'

const playlistUrl = ({ pk = '' } = {}) => `/playlists/${pk.split('#')[1]}`

const contentUrl = (item, from, position = null, extraParams = {}) => {
  let baseUrl
  if (item.type === 'playlist') {
    baseUrl = playlistUrl(item)
  } else if (item.type === 'external') {
    return item.url
  } else {
    baseUrl = item.staticUrl
      ? `/${item.locationId}/${item.staticUrl}`
      : `/${item.locationId}/${item.title.replace(/[^a-zA-Z0-9]/g, '-').toLowerCase()}`
  }

  const data = {
    ...(from && { from }),
    ...(position !== null && { position }),
    ...extraParams
  }
  const queryData = encodeQueryData(data)
  return `${baseUrl}${queryData.length ? `?${queryData}` : ''}`
}

// Always use the same separator
const qsArgs = { arrayFormat: 'comma' }

const qsParse = partialRight(queryString.parse, qsArgs)
const qsStringify = partialRight(queryString.stringify, qsArgs)

const categoryUrl = (category, pathToRoot) => {
  const pathname = categoryPath(category, pathToRoot)
    .slice(0, -1)
    .reverse()
    .map(({ staticUrl }) => staticUrl)
    .join('/')

  return `/${category.sk}/${pathname}`
}

const getCategoryPathForBreadcrumb = (category) => {
  return `/${category.sk}/${category.staticUrl}`
}

const categoryPath = (category, pathToRoot) => {
  const posInPath = pathToRoot.findIndex(({ sk }) => sk === category.sk)
  // if the category is in the path, return categories from root to its position
  // else insert the category into the path
  return posInPath > -1
    ? pathToRoot.slice(posInPath)
    : [category, ...pathToRoot]
}

export { contentUrl, playlistUrl, categoryUrl, getCategoryPathForBreadcrumb, categoryPath, qsParse, qsStringify }
