/* eslint-disable indent */
import React, { useCallback } from 'react'
import {
  bindPopover,
  bindTrigger,
  usePopupState
} from 'material-ui-popup-state/hooks'
import Menu from './menu'
import { useTheme } from '@mui/material'
import useActions from '../hooks/useActions'

const MenuButton = ({ id, button, filterContext = 'menu', disableActiveState }) => {
  const theme = useTheme()
  const buttonId = `${id}-button`
  const menuId = `${id}-menu`
  const popupState = usePopupState({
    variant: 'popover',
    popupId: menuId
  })

  const { filterActions } = useActions()
  const subActions = filterActions(filterContext)

  /**
   * Close the menu without invoking the onClick for the menu button
   * which would re-open the menu again.
   */
  const handleClickCapture = useCallback(
    evt => {
      if (popupState.isOpen) {
        evt.stopPropagation()
        popupState.close()
      }
    },
    [popupState]
  )

  const handleClose = useCallback(
    evt => {
      popupState.close(evt)
    },
    [popupState]
  )

  const menuButton = React.cloneElement(button, {
    id: buttonId,
    ...bindTrigger(popupState),
    onClickCapture: handleClickCapture,
    ...(popupState.isOpen &&
      !disableActiveState && {
        style: { backgroundColor: theme.palette.secondaryGrey.shade[5] }
      })
  })

  // When context does not have a sub action do not show the button.
  if (!subActions.length) {
    return <></>
  }

  return (
    <>
      {menuButton}
      <Menu
        {...bindPopover(popupState)}
        onClose={handleClose}
        filterContext={filterContext}
        aria-labelledby={buttonId}
      />
    </>
  )
}

export default MenuButton
