import React from 'react'
import { Divider, Grid } from '@mui/material'
import Container from '../container'
import LinkPanel from './link-panel'
import Legal from './legal'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(({ palette, spacing }) => ({
  content: {
    '& > *:not(:last-child)': {
      marginBottom: spacing(5)
    },
    paddingBottom: spacing(7)
  },
  divider: {
    backgroundColor: palette.grey.shade[20],
    marginBottom: spacing(6)
  }
}))

const PageFooter = () => {
  const classes = useStyles()

  return (
    <Container maxContentWidth={1200} inset={2}>
      <Divider variant='fullWidth' className={classes.divider} />
      <Grid
        container
        direction='column'
        alignItems='stretch'
        className={classes.content}
      >
        <Grid item>
          <LinkPanel />
        </Grid>
        <Grid item>
          <Legal />
        </Grid>
      </Grid>
    </Container>
  )
}

export default PageFooter
