import React from 'react'
import { useStyles } from './styles'
import B2CAuthAppBar from '../../components/b2c-auth-app-bar'
import { Box, Button, Container, Grid, Typography } from '@mui/material'
import { PageContainer } from '../../components'
import { useNavigate } from 'react-router-dom'
import { AccountCircleTwoTone } from '@mui/icons-material'

const B2CSignInAppBarButton = () => {
  const navigate = useNavigate()
  const goToLogin = () => navigate('/login')
  return (
    <Button startIcon={<AccountCircleTwoTone />} onClick={goToLogin} variant='outlined'>Sign In</Button>
  )
}

const B2CAuthPage = ({
  toolbarRightSlot,
  title,
  message,
  icon,
  content
}) => {
  const classes = useStyles()

  const hasTitle = !!title
  const hasMessage = !!message

  return (
    <>
      <B2CAuthAppBar
        rightSlot={toolbarRightSlot}
      />
      <PageContainer>
        <Container maxContentWidth={1200} inset={3}>
          <Grid container justifyContent='center' spacing={3} className={classes.root}>
            <Box display='flex' flexDirection='column' mt={2} mb={2} justifyContent='center' alignItems='center'>
              {icon}
              {hasTitle && <Typography className={classes.title}>{title}</Typography>}
              {hasMessage && <Typography className={classes.message}>{message}</Typography>}
              {content}
            </Box>
          </Grid>
        </Container>
      </PageContainer>
    </>
  )
}

export {
  B2CSignInAppBarButton
}

export default B2CAuthPage
