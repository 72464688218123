import { MSA_ACCESS } from './features'

export const verifyFeatureAccess = (features, feature) => {
  const access = features ? features[feature] : false

  // MSA_ACCESS is a special case where if the feature is not present, we assume access is not granted
  // This means that undefined cannot be returned as true
  if (feature === MSA_ACCESS) {
    return access
  }

  if (access === undefined || access === true) {
    return true
  }

  return false
}
