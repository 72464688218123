import React, { useCallback, useState } from 'react'
import { TenantProvider } from '@emerald-works-nova/auth'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import packageJson from '../package.json'
import * as DomainHelpers from './helpers/domain'
import store from './reducers/store'
import * as serviceWorker from './serviceWorker'
import { AppLoading, TenantError } from './components'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { createTheme } from './theme'
import { ApplicationRouterProvider } from './router'

if (
  DomainHelpers.basedURL().includes('ewnova.dev') ||
  DomainHelpers.basedURL().includes('mt3.dev') ||
  DomainHelpers.basedURL().includes('ewnova.live') ||
  DomainHelpers.basedURL().includes('mindtools.com')) {
  import('./libs/data-dog').then(datadogLib => {
    const params = {
      applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.eu',
      service: 'nova---toolkit',
      env: `${DomainHelpers.basedURL()}`,
      version: `v${packageJson.version}`,
      sessionSampleRate: parseInt(process.env.REACT_APP_DATADOG_SAMPLE_RATE) || 1,
      sessionReplaySampleRate: parseInt(process.env.REACT_APP_DATADOG_SESSION_REPLAY_SAMPLE_RATE) || 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input'
    }
    const datadogRum = datadogLib.default
    datadogRum.init(params)
    datadogRum.startSessionReplayRecording()
  })
}

const container = document.getElementById('root')
const root = createRoot(container)

const Main = () => {
  const [theme, setTheme] = useState(createTheme())

  const onChangeMainColor = useCallback((color) => {
    setTheme(createTheme({ palette: { primary: { main: color } } }))
  }, [])

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <TenantProvider
            ErrorComponent={TenantError}
            LoadingComponent={AppLoading}
            defaultTenantKey='www'
          >
            <ApplicationRouterProvider
              onChangeMainColor={onChangeMainColor}
            />
          </TenantProvider>
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

root.render(<Main />)

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
