import name from './name'

const selectSliceData = state => state[name]

const selectProfile = (state) => selectSliceData(state).profile
const selectIsProfileLoaded = state => selectSliceData(state).isProfileLoaded
const selectProfileLoadError = state => selectSliceData(state).hasProfileLoadError
const selectAuthEvents = state => selectSliceData(state).authEvents
// const selectProfileHasMSAEnabled = (state) => selectSliceData(state).profile?.features['msa:access']

export { selectProfile, selectIsProfileLoaded, selectProfileLoadError, selectAuthEvents }
