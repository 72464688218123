import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  container: ({ maxContentWidth, inset, disableGutters }) => {
    const needsPadding = (maxContentWidth + Number(spacing(inset).replace('px', '')) * 2)
    return {
      marginTop: disableGutters ? 0 : spacing(3),
      [breakpoints.down(needsPadding)]: {
        paddingLeft: spacing(inset),
        paddingRight: spacing(inset),
        width: '100vw'
        // width: `calc(100vw - ${spacing(2)} - 10px)`
      }
    }
  },
  content: ({ maxContentWidth }) => ({
    margin: 'auto',
    maxWidth: maxContentWidth
  })
}))
