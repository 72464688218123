import Auth from '@aws-amplify/auth'
import { useSession } from '@emerald-works/react-auth'
import { useConnectionContext } from '@emerald-works/react-event-bus-client'
import { useCallback } from 'react'

const useLogout = () => {
  const session = useSession()
  const connection = useConnectionContext()

  const logout = useCallback(
    async path => {
      const deleteCookies = () => {
        const cookies = document.cookie
        if (!cookies) {
          return
        }
        cookies.split(/; */).forEach(cookie => {
          const name = encodeURIComponent(cookie.split('=')[0])
          const newCookie = `${name}=;path=/;max-age=-1`
          document.cookie = newCookie
        })
      }

      const clearLocalStorage = () => {
        localStorage.clear()
      }

      const reload = async () => {
        try {
          await session.updateSessionContext()
          await connection.reloadConnection()
          window.location.href = new URL(path, window.location.href)
        } catch (err) {
          window.location.href = new URL(path, window.location.href)
        }
      }

      await Auth.signOut()
      deleteCookies()
      clearLocalStorage()
      await reload()
    },
    [session, connection]
  )

  return { logout }
}

export default useLogout
