import React, { useContext } from 'react'
import {
  AccountCircleTwoTone,
  AssignmentTwoTone,
  BookmarksTwoTone,
  BusinessCenterTwoTone,
  CollectionsBookmarkTwoTone,
  ExitToAppTwoTone,
  FaceTwoTone,
  HdrStrongTwoTone,
  InfoTwoTone,
  KeyboardArrowRight,
  MailTwoTone,
  WebTwoTone,
  CardGiftcardTwoTone,
  SupervisedUserCircleTwoTone,
  AdminPanelSettingsTwoTone,
  FactCheckTwoTone
} from '@mui/icons-material'
import { ActionButton } from '../../action-buttons'
import { routes } from '../../../routes'
import { Link, generatePath } from 'react-router-dom'
import { useBonusMaterial, useExternalApp, useGroup, useFeatureGuard } from '../../../hooks'
import makeStyles from '@mui/styles/makeStyles'
import MenuButton from '../menu-button'
import VerticalDivider from '../action-bar/divider'
import useAccessByContext from './useAccessByContext'
import { ALL_CONSUMER } from '../../../services/cognito/userGroups'
import { AppbarActionContext } from '../context'
import { Badge, Link as ExternalLink, SvgIcon } from '@mui/material'
import useFormFactor from './useFormFactor'
import { useDispatch, useSelector } from 'react-redux'
import { newsletterSlice } from '../../../reducers'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { SkillBitesIcon } from '../../../pages/skill-bites/components/skill-bites-icon'
import { useTenant } from '@emerald-works-nova/auth'
import consts from '../../../consts'
import { REPORTING_ACCESS, MSA_ACCESS } from '../../user-feature-guard/features'

export const FEATURE_GUARDS = {
  'reporting-action': REPORTING_ACCESS,
  'msa-action': MSA_ACCESS
}

const useStyles = makeStyles(({ typography, palette, breakpoints }) => {
  const common = isConsumerRole => ({
    fontWeight: typography.weight.regular,
    ...(isConsumerRole && { color: palette.secondaryGrey.shade[80] }),
    '& .MuiSvgIcon-root': {
      fontSize: '1.5rem'
    }
  })

  const commonLink = isConsumerRole => ({
    ...common(isConsumerRole),
    padding: '6px 11px',
    '& .MuiButton-startIcon': {
      marginRight: 6
    }
  })

  return {
    link: ({ isConsumerRole }) => ({
      ...commonLink(isConsumerRole)
    }),
    menuButton: ({ isConsumerRole }) => ({
      ...commonLink(isConsumerRole),
      '&:hover': {
        textDecoration: 'none'
      }
    }),
    close: ({ isConsumerRole }) => ({
      ...common(isConsumerRole),
      backgroundColor: palette.common.white,
      boxShadow: 'none'
    }),
    signin: ({ bps }) => ({
      [breakpoints.up(bps.smallDesktop)]: {
        // ActionButton variant ghost renders too high by the size of the border.
        paddingTop: 7,
        paddingBottom: 7
      }
    })
  }
})

function NewBadge (props) {
  return (
    <SvgIcon {...props}>
      <path d='M7.12 10.47V5.53003H6.09V8.41003L4.03 5.53003H3V10.47H4.03V7.59003L6.13 10.47H7.12ZM11.24 6.57003V5.53003H7.94V10.47H11.24V9.44003H9.18V8.53003H11.24V7.49003H9.18V6.57003H11.24ZM12.06 5.53003V9.65003C12.06 10.1 12.43 10.47 12.88 10.47H16.17C16.62 10.47 16.99 10.1 16.99 9.65003V5.53003H15.96V9.24003H15.04V6.35003H14.01V9.25003H13.08V5.53003H12.06V5.53003Z' />
      <path opacity='0.3' d='M2 2H18V14H2V2Z' />
      <path d='M18 0H2C0.89 0 0.00999999 0.89 0.00999999 2L0 14C0 15.11 0.89 16 2 16H18C19.11 16 20 15.11 20 14V2C20 0.89 19.11 0 18 0ZM18 14H2V2H18V14Z' />
    </SvgIcon>
  )
}

function BetaBadge (props) {
  return (
    <SvgIcon {...props}>
      <path d='M15.5829 10.9999L17.5119 6.31128H18.6305L20.5394 10.9999H19.4878L19.0123 9.76749H17.0765L16.5943 10.9999H15.5829ZM17.3779 8.99052H18.7176L18.0478 7.26241L17.3779 8.99052Z' />
      <path d='M13.8412 10.9999V7.16863H12.2605V6.31128H16.3865V7.16863H14.8125V10.9999H13.8412Z' />
      <path d='M8.28793 10.9999V6.31128H11.8915V7.15524H9.25916V8.22693H10.994V9.0441H9.25916V10.156H11.9183V10.9999H8.28793Z' />
      <path d='M3.70001 10.9999V6.31128H5.90368C6.22073 6.31128 6.49758 6.36486 6.73425 6.47203C6.97091 6.5792 7.15623 6.73103 7.29019 6.9275C7.42415 7.11952 7.49113 7.34279 7.49113 7.59731C7.49113 7.80272 7.43755 7.9925 7.33038 8.16665C7.22767 8.33634 7.08701 8.47476 6.9084 8.58193C7.13613 8.6891 7.31698 8.83423 7.45094 9.01731C7.5849 9.20039 7.65189 9.40803 7.65189 9.64023C7.65189 9.90815 7.58267 10.1448 7.44424 10.3502C7.31028 10.5556 7.11827 10.7164 6.86821 10.8325C6.62261 10.9441 6.33683 10.9999 6.01085 10.9999H3.70001ZM4.67124 8.22693H5.8434C6.05327 8.22693 6.21849 8.17781 6.33906 8.07958C6.46409 7.97687 6.52661 7.84068 6.52661 7.67099C6.52661 7.50131 6.46409 7.36734 6.33906 7.26911C6.21849 7.1664 6.05327 7.11505 5.8434 7.11505H4.67124V8.22693ZM4.67124 10.1962H5.93717C6.16044 10.1962 6.33906 10.1426 6.47302 10.0354C6.60698 9.92378 6.67396 9.77642 6.67396 9.59334C6.67396 9.4058 6.60698 9.25844 6.47302 9.15127C6.33906 9.03963 6.16044 8.98382 5.93717 8.98382H4.67124V10.1962Z' />
      <path opacity='0.3' d='M2 2H22V14H2V2Z' />
      <path d='M22 0H2C0.89 0 0.0100002 0.89 0.0100002 2L0 14C0 15.11 0.89 16 2 16H22C23.11 16 24 15.11 24 14V2C24 0.89 23.11 0 22 0ZM22 14H2V2H22V14Z' />
    </SvgIcon>
  )
}

const useActions = () => {
  const { isInGroup } = useGroup()
  const { breakpoints, formFactor } = useFormFactor()
  const { newestBonusMaterialCount } = useBonusMaterial()
  const tenant = useTenant()
  const { hasFeatureAccess } = useFeatureGuard()
  const isPopoverOpen = useSelector(newsletterSlice.selectors.selectIsNewsletterPopoverOpen)
  const [trackNewsletterOpenPopover] = useEvent([{ eventName: 'newsletterOpenPopover' }])
  const classes = useStyles({
    isConsumerRole: isInGroup(ALL_CONSUMER),
    bps: breakpoints
  })
  const { getMtUrl, arkUrl, reportingUrl } = useExternalApp()

  const isSmallDisplay = ['mobile', 'tablet'].includes(formFactor)
  const linkSize = isSmallDisplay ? 'xl' : 'm'
  const buttonSize = isSmallDisplay ? 'l' : 's'

  const handleUserManagementPage = () => {
    window.open(`${arkUrl}tenants/${tenant.pk}`)
  }

  const handleOpenReportingDashboard = () => {
    window.open(reportingUrl)
  }

  /* Actions need both an id (used to access check) and a key (needed by React).
   * The key alone can't be used as it's mutated at various points by React.
   *
   * Actions whose onClick can't be fully handled in here (e.g. those that set
   * modal state in other components) can use the AppbarActionContext's onClick
   * or onShowMenu functions. The onClick is fired for every action. E.g. See the
   * explore action handling in the app toolbar.
   */
  const allActions = [
    <ActionButton
      id='explore-action'
      key='explore-action'
      variant={isInGroup(ALL_CONSUMER) ? 'publicTenantSecondary' : 'primary'}
      fullWidth
      size='xl'
      endIcon={<KeyboardArrowRight />}
      label='Explore'
      data-test='appbar-expore-button'
    />,
    <ActionButton
      id='howitworks-action'
      key='howitworks-action'
      variant='link'
      component={ExternalLink}
      href={getMtUrl('howitworks')}
      size={linkSize}
      startIcon={<InfoTwoTone />}
      label='How it works'
      data-test='appbar-howitworks-button'
      className={classes.link}
    />,
    <ActionButton
      id='blog-action'
      key='blog-action'
      variant='link'
      component={ExternalLink}
      href={getMtUrl('blog')}
      size={linkSize}
      startIcon={<WebTwoTone />}
      label='Blog'
      data-test='appbar-blog-button'
      className={classes.link}
    />,
    <Badge
      key='bonusmaterial-action'
      id='bonusmaterial-action'
      badgeContent={newestBonusMaterialCount}
      color='primary'
      overlap='circular'
    >
      <ActionButton
        key='bonusmaterial-action'
        id='bonusmaterial-action'
        variant='link'
        component={Link}
        to={routes['bonus-material'].path}
        size={linkSize}
        startIcon={<CardGiftcardTwoTone />}
        label='Bonus'
        data-test='appbar-bonusmaterial-button'
        className={classes.link}
      />
    </Badge>,
    <ActionButton
      id='newsletter-action'
      key='newsletter-action'
      variant='link'
      onClick={() => {
        if (!isPopoverOpen) {
          trackNewsletterOpenPopover.trigger({
            from: 'appbar'
          })
        }
        dispatch(newsletterSlice.actions.toggleNewsletterPopover())
      }}
      size={linkSize}
      startIcon={<MailTwoTone />}
      label='Newsletter'
      data-test='appbar-newsletter-button'
      className={classes.link}
    />,
    <ActionButton
      id='forbusiness-action'
      key='forbusiness-action'
      variant='link'
      component={ExternalLink}
      href={getMtUrl('forbusiness')}
      size={linkSize}
      startIcon={<BusinessCenterTwoTone />}
      label='For Business'
      data-test='appbar-forbusiness-button'
      className={classes.link}
    />,
    <ActionButton
      id='msa-action'
      key='msa-action'
      variant='link'
      component={Link}
      to={generatePath(routes['assessment-details'].path, { assessmentId: 'manager-skill-assessment' })}
      size={linkSize}
      startIcon={<FactCheckTwoTone />}
      label='Manager Skills Assessment'
      data-test='appbar-msa-button'
      className={classes.link}
      endIcon={<BetaBadge style={{ height: 30, width: 30 }} viewBox='0 0 28 28' />}
    />,
    <ActionButton
      key='skillbites-action'
      id='skillbites-action'
      variant='link'
      component={Link}
      to={routes['skill-bites'].path}
      size={linkSize}
      startIcon={<SkillBitesIcon />}
      label='Skill Bites'
      data-test='appbar-skillbites-button'
      className={classes.link}
    />,
    <ActionButton
      id='playlists-action'
      key='playlists-action'
      variant='link'
      component={Link}
      to={routes.playlists.path}
      size={linkSize}
      startIcon={<CollectionsBookmarkTwoTone />}
      label='Playlists'
      data-test='appbar-playlist-button'
      className={classes.link}
      popper={{
        enabled: true,
        title: 'Click here to access playlists',
        content: 'Create your own Playlist, by adding and organizing articles, resources and tools in one place.'
      }}
    />,
    <ActionButton
      id='reporting-action'
      key='reporting-action'
      variant='link'
      size={linkSize}
      startIcon={<AssignmentTwoTone />}
      label='Reporting'
      data-test='appbar-reporting-button'
      onClick={handleOpenReportingDashboard}
      className={classes.link}
      endIcon={tenant.features?.skillBites && <NewBadge style={{ height: 30, width: 30 }} viewBox='0 0 28 28' />}
    />,
    <ActionButton
      id='usermanagement-action'
      key='usermanagement-action'
      variant='link'
      size={linkSize}
      startIcon={<SupervisedUserCircleTwoTone />}
      label='User Management'
      data-test='user-management-button'
      onClick={handleUserManagementPage}
      className={classes.link}
    />,
    <ActionButton
      id='bookmarks-action'
      key='bookmarks-action'
      variant='link'
      component={Link}
      to={routes.bookmarks.path}
      size={linkSize}
      startIcon={<BookmarksTwoTone />}
      label='Bookmarks'
      data-test='appbar-bookmarks-button'
      className={classes.link}
    />,
    <ActionButton
      id='noauthbookmarks-action'
      key='noauthbookmarks-action'
      variant='link'
      size={linkSize}
      startIcon={<BookmarksTwoTone />}
      label='Bookmarks'
      data-test='appbar-bookmarks-button'
      className={classes.link}
    />,
    <MenuButton
      id='admintools-action'
      key='admintools-action'
      filterContext='adminTools'
      button={
        <ActionButton
          variant='link'
          size='m'
          startIcon={<AdminPanelSettingsTwoTone />}
          endIcon={tenant.features?.skillBites && <NewBadge style={{ height: 30, width: 30 }} viewBox='0 0 28 28' />}
          label='Admin Tools'
          data-test='appbar-admin-tools-button'
          className={classes.menuButton}
        />
      }
    />,
    <ActionButton
      id='profile-action'
      key='profile-action'
      variant='link'
      component={Link}
      to={routes.profile.path}
      size={linkSize}
      startIcon={<FaceTwoTone />}
      label='Me'
      data-test='appbar-profile-button'
      className={classes.link}
    />,
    <ActionButton
      id='logout-action'
      key='logout-action'
      variant='link'
      size={linkSize}
      startIcon={<ExitToAppTwoTone />}
      label='Logout'
      data-test='appbar-profile-button'
      className={classes.link}
    />,
    <ActionButton
      id='join-action'
      key='join-action'
      variant='join'
      component={ExternalLink}
      href={getMtUrl('join')}
      size={buttonSize}
      fullWidth
      label='Join Mind Tools'
      data-test='appbar-join-button'
      className={classes.join}
    />,
    <ActionButton
      id='signin-action'
      key='signin-action'
      variant='ghost'
      component={Link}
      to={routes.login.path}
      fullWidth
      size={buttonSize}
      startIcon={<AccountCircleTwoTone />}
      label='Sign In'
      data-test='appbar-profile-button'
      className={classes.signin}
    />,
    <MenuButton
      id='more-action'
      key='more-action'
      button={
        <ActionButton
          variant='link'
          size='m'
          startIcon={<HdrStrongTwoTone />}
          label='More'
          data-test='appbar-show-more-button'
          className={classes.menuButton}
        />
      }
    />,
    <MenuButton
      id='account-action'
      key='account-action'
      button={
        <ActionButton
          variant='link'
          size='m'
          startIcon={<AccountCircleTwoTone />}
          label='Account'
          data-test='appbar-account-button'
          className={classes.menuButton}
        />
      }
    />,
    <VerticalDivider id='divider' key='divider' />
  ].filter(({ props: { id } }) => {
    const isClub = tenant.pk === consts.DEFAULT_CLUB_PK
    const guardName = FEATURE_GUARDS[id]

    if (!tenant) return false
    if (id === 'usermanagement-action') return tenant.toolkitType === consts.TOOLKIT_TYPE.smallBusinessToolkit
    if (id === 'skillbites-action') return tenant.features?.skillBites
    // MSA needs to check both if the feature is active on the Tenant and if the user has access to it
    if (id === 'msa-action') return (tenant.features?.msa && hasFeatureAccess(guardName))
    // Always ignore playlist feature flag for club
    if (id === 'playlists-action') return tenant.features?.playlists || isClub
    if (guardName) { return hasFeatureAccess(guardName) }
    return true
  })

  const dispatch = useDispatch()
  const { getId } = useContext(AppbarActionContext)

  const { accessInContext } = useAccessByContext()

  const filterActions = context => {
    return allActions.filter(action => accessInContext(context, getId(action)))
  }

  return { filterActions }
}

export default useActions
