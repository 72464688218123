import name from './name'

const selectSliceData = state => state[name]
const selectAssessmentDetails = state => selectSliceData(state)
const selectLoadingAssessment = state => selectSliceData(state).loadingAssessment
const selectHasError = state => selectSliceData(state).hasError

export {
  selectAssessmentDetails,
  selectLoadingAssessment,
  selectHasError
}
