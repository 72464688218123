import { verifyFeatureAccess } from '../components/user-feature-guard/utils'
import useGroup from './useGroup'
import useProfileSession from './useProfileSession'

const useFeatureGuard = () => {
  const { profile } = useProfileSession()
  const { isInGroup } = useGroup()

  const hasAccess = (allowedGroups, feature) => {
    return (
      isInGroup(allowedGroups) &&
      verifyFeatureAccess(profile?.features, feature)
    )
  }

  const hasFeatureAccess = (feature) => {
    return verifyFeatureAccess(profile?.features, feature)
  }

  return {
    hasAccess,
    hasFeatureAccess
  }
}

export default useFeatureGuard
