import React, { useEffect } from 'react'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import App from './App'

import PrimaryLayout from './layouts/primary-layout'
import SecondaryLayout from './layouts/secondary-layout'
import AuthNoLayout from './layouts/auth-no-layout'
import { useTenant } from '@emerald-works-nova/auth'
import NotFoundPage from './pages/not-found'
import MaintenancePage from './pages/maintenance'
import { SignInCompletion } from '@emerald-works/react-auth'
import { routes } from './routes'

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        element: <PrimaryLayout />,
        children: [
          {
            index: true,
            element: <Navigate to='/home' />
          },
          {
            path: '/index.html',
            element: <Navigate to='/home' />
          },
          {
            path: routes.article.path,
            lazy: () => import('./pages/location')
          },
          {
            path: routes.home.path,
            lazy: () => import('./pages/home')
          },
          {
            path: routes.bookmarks.path,
            lazy: () => import('./pages/bookmarks')
          },
          {
            path: routes.serp.path,
            lazy: () => import('./pages/search-results')
          },
          {
            path: routes.profile.path,
            lazy: () => import('./pages/profile')
          },
          {
            path: routes.retention.path,
            lazy: () => import('./pages/profile/components/subscription-and-billing/components/retention')
          },
          {
            path: routes.playlists.path,
            lazy: () => import('./pages/playlists')
          },
          {
            path: routes.playlist.path,
            lazy: async () => {
              const { PlaylistViewPage } = await import('./pages/playlist-detail')
              return {
                Component: PlaylistViewPage
              }
            }
          },
          {
            path: routes['playlist-edit'].path,
            lazy: async () => {
              const { PlaylistEditPage } = await import('./pages/playlist-detail')
              return {
                Component: PlaylistEditPage
              }
            }
          },
          {
            path: routes['playlist-create'].path,
            lazy: async () => {
              const { PlaylistCreatePage } = await import('./pages/playlist-detail')
              return {
                Component: PlaylistCreatePage
              }
            }
          },
          {
            path: routes['skill-bites'].path,
            lazy: () => import('./pages/skill-bites')
          },
          {
            path: routes['skill-bite'].path,
            lazy: () => import('./pages/skill-bite-detail')
          },
          {
            path: routes['skill-bite-session'].path,
            lazy: () => import('./pages/skill-bite-session')
          },
          {
            path: routes['assessment-details'].path,
            lazy: () => import('./pages/assessment-details')
          },
          {
            path: routes['privacy-policy'].path,
            lazy: () => import('./pages/policies/privacy')
          },
          {
            path: routes['cookie-policy'].path,
            lazy: () => import('./pages/policies/cookies')
          },
          {
            path: routes.tandc.path,
            lazy: () => import('./pages/policies/tandc')
          },
          {
            path: routes['a11y-statement'].path,
            lazy: () => import('./pages/policies/a11y-statement')
          },
          {
            path: routes['newest-releases'].path,
            lazy: () => import('./pages/newest-releases')
          },
          {
            path: routes['most-popular'].path,
            lazy: () => import('./pages/most-popular')
          },
          {
            path: routes['bonus-material'].path,
            lazy: () => import('./pages/bonus-material')
          }
        ]
      },
      {
        element: <SecondaryLayout />,
        children: [
          {
            path: routes.login.path,
            lazy: () => import('./pages/sign-in')
          },
          {
            path: routes.register.path,
            lazy: () => import('./pages/sign-up')
          },
          {
            path: routes['recovery-password'].path,
            lazy: () => import('./pages/password-recovery')
          }
        ]
      },
      {
        element: <AuthNoLayout />,
        children: [
          {
            path: routes.b2cFreeRegister.path,
            lazy: async () => {
              const { B2CSignUpFree } = await import('./pages/b2c-signup')
              return {
                Component: B2CSignUpFree
              }
            }
          },
          {
            path: routes.b2cRegister.path,
            lazy: () => import('./pages/b2c-signup')
          },
          {
            path: routes.b2cSocialSignUpRedirect.path,
            lazy: () => import('./pages/b2c-social-redirect')
          },
          {
            path: routes.b2cRegisterSuccess.path,
            lazy: () => import('./pages/b2c-signup-success')
          },
          {
            path: routes.b2cFreeRegisterSuccess.path,
            lazy: async () => {
              const { B2CSignupSuccessFree } = await import('./pages/b2c-signup-success')
              return {
                Component: B2CSignupSuccessFree
              }
            }
          },
          {
            path: routes['verify-email'].path,
            lazy: () => import('./pages/verify-email')
          },
          {
            path: routes['resend-email'].path,
            lazy: () => import('./pages/resend-email')
          },
          {
            path: routes['recovered-password'].path,
            lazy: () => import('./pages/password-recovered')
          },
          {
            path: routes['reset-password-end'].path,
            lazy: () => import('./pages/password-reset-end')
          },
          {
            path: routes['reset-password'].path,
            lazy: () => import('./pages/password-reset')
          },
          {
            path: routes['change-password'].path,
            lazy: () => import('./pages/password-force-change')
          },
          {
            path: routes.maintenance.path,
            element: <MaintenancePage />
          },
          {
            path: routes.signin.path,
            element: <SignInCompletion />
          },
          {
            path: routes.internalLogin.path,
            lazy: () => import('./pages/admin-internal-login')
          }
        ]
      },
      {
        path: '*',
        element: <NotFoundPage />
      }
    ]
  }
])

export const ApplicationRouterProvider = ({ onChangeMainColor }) => {
  const tenant = useTenant()

  useEffect(() => {
    if (tenant && tenant.mainColor) {
      onChangeMainColor(tenant.mainColor)
    }
  }, [tenant, onChangeMainColor])

  if (!tenant) return null

  return (
    <RouterProvider
      router={router}
    />
  )
}
